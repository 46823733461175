/* RestorationResources.css */

.restoration-resources-page {
    font-family: 'Fira Sans', Arial, sans-serif;
    color: #000000;
    line-height: 1.6;
    margin: 0 auto;
    padding: 20px;
}

.hero-section h1 {
    font-size: 48px;
    color: #2c6598;
    margin-bottom: 20px;
}

.content-section {
    margin-bottom: 40px;
    padding: 30px;
    border-radius: 8px;
}

h2 {
    font-size: 36px;
    color: #ff9900;
    text-align: center;
    margin-bottom: 30px;
}

h3 {
    font-size: 24px;
    color: #2c6598;
    margin-top: 30px;
    margin-bottom: 15px;
}

h4 {
    font-size: 20px;
    color: #333;
    margin-top: 25px;
    margin-bottom: 10px;
}

p {
    margin-bottom: 15px;
}

.open-letter {
    background-color: #f9f9f9;
    padding: 30px;
    border-radius: 8px;
    margin-bottom: 40px;
}

.tip-container {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 30px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.tip-container img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

a {
    color: #2c6598;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.restoration-resources-page img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px auto;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.open-letter img {
    max-width: 600px;
    float: right;
    margin: 0 0 20px 20px;
}

.tip-container img {
    max-width: 100%;
    margin: 20px auto;
}

/* Clear float after open-letter section */
.open-letter::after {
    content: "";
    display: table;
    clear: both;
}

.tip-image-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.tip-image {
    width: 48%;
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.tip-image:hover {
    transform: scale(1.05);
}

.tip-image img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
}

.tip-image h3 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    margin: 0;
    padding: 10px;
    text-align: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.tip-container {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .tip-image-container {
        flex-direction: column;
    }

    .tip-image {
        width: 100%;
        margin-bottom: 20px;
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .open-letter img {
        float: none;
        margin: 20px auto;
    }

    .tip-container img {
        max-width: 100%;
    }
}

/* Styles to make Navbar non-transparent */
.restoration-resources-page .navbar-section {
    background-color: #ff9900;  /* or any color you prefer */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);  /* optional: adds a subtle shadow */
}

.restoration-resources-page .navbar-links {
    color: white;  /* change this to match your desired text color */
}

.restoration-resources-page .navbar-title a {
    color: #2c6598;  /* change this to match your desired title color */
}

/* If your mobile menu has a different background, you might want to adjust that too */
.restoration-resources-page .mobile-navbar {
    background-color: #ff9900;  /* or any color you prefer */
}

.restoration-resources-page .mobile-navbar-links li a {
    color: white;  /* change this to match your desired text color */
}

/* Adjust the top margin of the main content to account for the non-transparent navbar */
.restoration-resources-page main {
    margin-top: 80px;  /* adjust this value based on your navbar height */
}

@media (max-width: 768px) {
    .restoration-resources-page {
        padding: 10px;
    }

    .hero-section {
        padding: 40px 0;
    }

    .hero-section h1 {
        font-size: 36px;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 22px;
    }

    h4 {
        font-size: 18px;
    }

    .open-letter, .tip-container {
        padding: 20px;
    }
}