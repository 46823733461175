.second-row {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  overflow: auto;
}

.body-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}