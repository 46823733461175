.flood-content {
    font-family: 'Arial', sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    padding-bottom: 100px;
    min-height: 100vh;
  }
  
  .flood-header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .logo {
    margin-bottom: 10px;
  }
  
  .logo img {
    max-width: 150px;
    display: block;
    margin: 0 auto;
  }
  
  .contact-info {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .contact-info p {
    margin: 0;
    font-size: 14px;
    color: #00a0e3;
  }
  
  .contact-info h2 {
    margin: 5px 0;
    font-size: 16px;
    color: #333;
    text-align: center;
  }
  
  .phone-number {
    font-size: 18px;
    color: #ff7f00;
    text-decoration: none;
    display: block;
    text-align: center;
  }
  
  .help-banner {
    background-color: #f0f0f0;
    padding: 10px 20px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 100%;
  }
  
  .help-banner h2 {
    margin: 0;
    color: #00a0e3;
    font-size: 20px;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    position: relative;
  }
  
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .modal h2 {
    margin-bottom: 20px;
    color: #2c6598;
  }
  
  .modal form {
    display: flex;
    flex-direction: column;
  }
  .modal input:focus {
    outline: 2px solid #2c6598;
    border-color: #2c6598;
  }
  .modal input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .modal button[type="submit"] {
    background-color: #ff7f00;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .modal button[type="submit"]:hover {
    background-color: #e67300;
  }
  
  .time-options a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }
  
  .time-options a:hover {
    text-decoration: underline;
  }
  
  .time-options {
    margin-top: 5px;
    font-size: 16px;
  }
  
  .time-options .now {
    color: #ff7f00;
  }
  
  .main-content {
    margin-bottom: 20px;
  }
  
  .main-content h1 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .content-flex {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .text-content, .image-content {
    width: 100%;
    font-size: x-large;
  }
  
  .call-now {
    font-size: 22px;
    font-weight: bold;
    color: #ff7f00;
  }
  
  .image-content img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .resources-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 100px;
  }
  
  .mascot {
    width: 100px;
  }
  
  .mascot img {
    max-width: 100%;
    height: auto;
  }
  
  .resto-resources-card {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 4px;
    width: 100%;
  }
  
  .resto-resources-card h3 {
    color: #00a0e3;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .resto-resources-card ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .resto-resources-card li {
    margin-bottom: 8px;
    font-size: 14px;
  }
  
  .location-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f0f0f0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  .location-info .expert-distance {
    font-size: 18px;
    margin: 10px 0;
    color: #2c6598;
  }
  
  .location-info .highlight-text {
    color: #ff7f00;
    animation: pulse 2s infinite;
  }

  .date-picker-container {
    margin-bottom: 15px;
  }
  
  .date-picker-container label {
    display: block;
    margin-bottom: 5px;
  }
  
  .react-datepicker-wrapper {
    width: 100%;
  }
  
  .react-datepicker__input-container input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .date-picker-container {
    margin-bottom: 15px;
  }
  
  .date-picker-container label {
    display: block;
    margin-bottom: 5px;
  }
  
  .custom-datepicker-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px; /* Prevent zoom on iOS */
    cursor: pointer;
  }
  
  /* Increase touch target size for mobile */
  @media (max-width: 768px) {
    .custom-datepicker-input {
      padding: 15px 10px;
    }
  
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
      padding: 10px 5px;
      height: auto;
    }
  }
  
  /* Ensure the date picker dropdown is fully visible */
  .react-datepicker-popper {
    z-index: 9999 !important;
  }
  
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  
  .location-info h3 {
    margin: 0;
    color: #ff7f00;
    font-size: 16px;
  }
  
  .location-info p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #00a0e3;
  }
  
  .service-rating {
    text-align: center;
    margin: 10px 0;
  }
  
  .service-rating p {
    margin: 0;
    font-size: 14px;
  }
  
  .stars {
    color: #ff7f00;
    font-size: 14px;
  }
  
  .call-button {
    background-color: #ff7f00;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: 20px;
    width: 100%;
    text-align: center;
  }
  
  .out-of-service {
    color: #ff0000;
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 14px;
  }
  
  .location-error, .loading {
    text-align: center;
    font-style: italic;
    width: 100%;
    font-size: 14px;
  }
  
  @media screen and (min-width: 768px) {
    .flood-header {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .location-info .expert-distance {
        font-size: 20px;
      }
  
    .header-top {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 0;
    }
  
    .logo {
      margin-bottom: 0;
      flex: 1;
    }
  
    .logo img {
      margin: 0;
      max-width: 150px;
    }
  
    .contact-info {
      align-items: flex-end;
      text-align: right;
      flex: 1;
    }
  
    .contact-info h2,
    .phone-number {
      text-align: right;
    }
  
    .help-banner {
      width: auto;
      margin: 0 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  
    .content-flex {
      flex-direction: row;
    }
  
    .text-content, .image-content {
      width: 48%;
    }
  
    .resources-section {
      flex-direction: row;
      align-items: flex-start;
    }
  
    .mascot {
      width: 150px;
    }
  
    .resto-resources-card {
      width: auto;
      flex-grow: 1;
    }
  
    .location-banner {
      flex-direction: row;
      justify-content: space-between;
      padding: 15px 20px;
    }
  
    .call-button {
      width: auto;
    }
  }